<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="teachersStore.teacher.d_nameDisplay"/>

      <!--Function Bar-->
      <teacher-function-bar />

      <!--Teacher Education List-->
      <teacher-educations :key="teachersStore.teacher.id" :education-section="2" />

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import TeacherFunctionBar from '@/components/TeacherFunctionBar';
import TeacherEducations from '@/components/TeacherEducations';

export default {
  name: 'TeacherEducation',
  mixins: [mixin],
  computed: {
    ...mapState({
      teachersStore: (state) => state.teachersStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
	  TitleBar,
	  TeacherFunctionBar,
	  TeacherEducations,
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('teachersStore/read', this.$route.params.teacherUUID);
      this.$store.dispatch('progressStore/set', false);
	  },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
