<template>
  <div>
    <!-- Title Bar For Section List-->
    <v-card flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-graduation-cap</v-icon>
        <v-toolbar-title class="pl-2">Education</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!--Pagination  -->
    <pagination
      :show="educationStore.education.data && educationStore.education.data.length > 0"
      :showTotal="true"
      :currentPage="educationStore.education.current_page"
      :lastPage="educationStore.education.last_page"
      :total="educationStore.education.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--Education List-->
      <v-list
        v-if="educationStore.education && educationStore.education.length > 0"
        two-line
      >
        <template v-for="item in educationStore.education">
          <v-list-item
            :key="item.id"
            @click.stop="
              openEntry(
                item.id
              )
            "
            class="my-1"
          >
            <v-list-item-content>
              <v-list-item-title class="light-blue--text text--darken-4">
                Institution: {{item.d_schoolName}} &nbsp; ({{item.d_enrolled}})
              </v-list-item-title>
              <v-list-item-title class="light-blue--text text--darken-4">
                Degree/ Matriculation: {{item.d_degreeSought}} &nbsp; ({{item.d_type}})
              </v-list-item-title>
              <v-list-item-title class="light-blue--text text--darken-4">
                <!--<span v-if="$_dateExpired(item.d_expectedCompletion)">
                  Completion:
                </span>
                <span v-else>
                  Pending Completion:
                </span> {{ $_formatDate(item.d_expectedCompletion) }} &nbsp;-->
                <span>
                  Completion Status: {{item.f_completion ? 'Completed' : 'In Progress'}}
                </span>
               </v-list-item-title>
               <v-list-item-title
                  class="text-caption grey--text"
                  :class="$vuetify.theme.dark === false ? 'text--darken-1' : ''"
                >
                Created: {{ $_formatTimestamp(item.created_at) }} &#8226; Modified:
                {{ $_formatTimestamp(item.updated_at) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text
                ><span
                  class="text-body-1"
                  :class="
                    item.f_status === 1 || item.f_status === true
                      ? 'font-weight-bold green--text text--lighten-1'
                      : 'error--text'
                  "
                  >{{ $_activeStatus(item.f_status) }}</span
                >
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-list>

      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Degrees or Certificates Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers} from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import { uploadHeader } from "@/env";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";

const { mapFields } = createHelpers({
  getterType: "teachersStore/getFilterField",
  mutationType: "teachersStore/updateFilterField"
});

export default {
  name: "TeacherEducations",
  components: {
    TitleBar,
    Pagination
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      teachersStore: state => state.teachersStore,
      educationStore: state => state.educationStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "page",
      "section",
    ])
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      filterMenu: false
    };
  },
  methods: {
    async initialize() {
      /* Grab teacher detail using uuid passed as url parameter */
      const teacher = this.$store.getters["teachersStore/getTeacher"];

      /* Pull education list */
      const data = {
        fk_teacherID: teacher.id
      };
      await this.$store.dispatch("educationStore/fetch", data);
      this.$store.dispatch("progressStore/set", false);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    async openEntry(id) {
      const teacher = this.$store.getters["teachersStore/getTeacher"];
      const data2 = {
        fk_teacherID: teacher.id,
        id: id
      };
      await this.$store.dispatch("educationStore/entry", data2);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
